import React from 'react';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';

const COPIES = strings.dailyPlan;

export const DailyPlan = () => {
  usePageTitle({ title: PageTitles.DailyPlan });

  return (
    <FiltersLayout title={COPIES.title} titleLabelProps={backNavigateListConfig}>
      <h5>Content</h5>
    </FiltersLayout>
  );
};
